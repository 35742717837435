<template>
  <div class="content">
    <heads></heads>
    <div style="padding: 90px 120px;">
      <div class="main">
        <div class="main-left">
          <div class="main-left-tit" :class="[activeIndex == 1 ? 'active' : '']" @click="btn(1)">{{ $t('my.grzx') }}</div>
          <div class="main-left-tit" :class="[activeIndex == 2 ? 'active' : '']" @click="btn(2)">{{ $t('my.sfrz') }}</div>
          <div class="main-left-tit" :class="[activeIndex == 3 ? 'active' : '']" @click="btn(3)">{{ $t('my.aqzx') }}</div>
          <div class="main-left-tit" :class="[activeIndex == 4 ? 'active' : '']" @click="btn(4)">{{ $t('my.lyfk') }}</div>
        </div>
        <div class="main-right" v-if="activeIndex == 1">
          <div class="main-right-heade">
            <div class="">
              <img class="avter" src="../../assets/market/tx.png">
            </div>
            <div>
              <div style="display: flex;align-items: center;">
                <div class="username">{{listObjeck.email}}</div>
                <!-- <img class="edit" src="../../assets/market/edit.png" alt=""> -->
              </div>
              <div class="account ">
                <span style="margin-right: 40px;">{{ $t('my.zh') }}：{{listObjeck.email}} </span>
                <span style="margin-right:40px;">ID：{{ listObjeck.invite_code }}</span>
                {{ $t('my.zcsj') }}：{{listObjeck.created_at}}
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="record">{{ $t('my.zjdl') }}</div>
          <div class="quotation-list">
            <div class="quotation-list-tit">
              <div>{{ $t('my.sj') }}</div>
              <div style="text-align: center;">IP</div>
              <div style="text-align: right;">{{ $t('my.dd') }}</div>

            </div>
          </div>
          <div class="scroll">
            <div class="quotation-list" v-for="(item, index) in getData" :key="index">
              <div class="quotation-list-tit">
                <div>{{item.created_at}}</div>
                <div style="text-align: center;">{{item.ip}}</div>
                <div style="text-align: right;">{{item.city}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="main-right" v-if="activeIndex == 2">
          <div class="main-right-heade">
            <div class="">
              <img class="avter2" src="../../assets/market/rz.png">
            </div>
            <div>
              <div style="display: flex;align-items: center;">
                <div class="username">{{ $t('my.sfrz') }}</div>
              </div>
              <div class="account ">
<!--                <span style="margin-right: 40px;">① {{ $t('my.ed') }}2000 USDT （24H）</span>-->
<!--                <span style="margin-right:40px;">② {{ $t('my.wc') }}</span>-->
                <!-- <span > {{ $t('my.wc') }}</span> -->
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="box-sm">
            <div>
              <div class="fs-16" style="color:#FFF;">{{ $t('my.rz') }}</div>
              <!-- <div style="font-size: 12px;color: rgba(255, 255, 255, 0.8);">{{ $t('my.tged') }}2000 USDT</div> -->
            </div>
            <div class="authentication" @click="jumpRz" v-if="listObjeck.auth_type!==1 && listObjeck.auth_type!==2">{{ $t('my.qrz') }}</div>
            <div class="authentication authentication-two"  v-if="listObjeck.auth_type===1 ">{{ $t('my.yrz1') }}</div>
            <div class="authentication authentication-two" v-else >{{ $t('my.yrz') }}</div>
          </div>
          <div class="line2"></div>
        </div>
        <div class="main-right" v-if="activeIndex == 3">
          <div class="main-right-heade">
            <div class="">
              <img class="avter2 only-img"  src="../../assets/market/aq.png">
            </div>
            <div>
              <div style="display: flex;align-items: center;">
                <div class="username">{{ $t('my.aqzx') }}</div>
              </div>
              <div class="account ">
                <span style="margin-right: 40px;">{{ $t('my.dj') }}：{{ $t('my.g') }}</span>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="box-sm">
            <div style="display: flex;align-items: center;">
              <div>
                <img class="box-sm-img" src="../../assets/market/ys.png" alt="">
              </div>
              <div>
                <div class="fs-16" style="color:#FFF;margin-bottom: 10px;">{{ $t('my.dlma') }}</div>
                <div class="fs-12" style="color: rgba(255, 255, 255, 0.8);">{{ $t('my.dlma2') }}</div>
              </div>
            </div>
            <div style="font-size: 14px; color: #FFC300;cursor: pointer;" @click="edit(1)">{{ $t('my.xg') }}</div>
          </div>
          <!-- <div class="line2"></div>
           <div class="box-sm">
            <div style="display: flex;align-items: center;">
              <div>
                <img class="box-sm-img" src="../../assets/market/yx.png" alt="">
              </div>
               <div>
                 <div style="font-size: 16px;color:#FFF;margin-bottom: 10px;">{{$t('my.yxyz')}} <span style="color: rgba(255, 255, 255, 0.6);">akf****nd@abcd.com</span></div>
                 <div style="font-size: 12px;color: rgba(255, 255, 255, 0.8);">{{$t('my.yxyz2')}}</div>
               </div>
            </div>
             <div style="font-size: 14px; color: #FFC300;cursor: pointer;" @click="edit(2)">{{$t('my.xg')}}</div>
           </div> -->
          <div class="line2"></div>
          <div class="box-sm">
            <div style="display: flex;align-items: center;">
              <div>
                <img class="box-sm-img" src="../../assets/market/pwd.png" alt="">
              </div>
              <div>
                <div class="fs-16" style="color:#FFF;margin-bottom: 10px;">{{ $t('my.jyma') }}</div>
                <div class="fs-12" style="color: rgba(255, 255, 255, 0.8);">{{ $t('my.jyma2') }}</div>
              </div>
            </div>
            <div style="font-size: 14px; color: #FFC300;cursor: pointer;" @click="edit(3)">{{ $t('my.sz') }}</div>
          </div>
        </div>
        <div class="main-right" v-if="activeIndex == 4">
          <div class="" style="display: flex;align-items: center;justify-content: space-between">
            <div class="record">{{ $t('my.lyfk') }}</div>
            <div style="font-size: 14px; color: #FFC300;cursor: pointer;padding-right: 40px" @click="addMsg(1)">{{ $t('my.hftext') }}</div>
          </div>


          <div class="quotation-list">
            <div class="quotation-list-tit">
              <div>{{ $t('my.sj') }}</div>
              <div style="text-align: center;">{{ $t('my.lytext') }}</div>
              <div style="text-align: right;">{{ $t('my.hftext') }}</div>

            </div>
          </div>
          <div class="scroll">
            <div class="quotation-list" v-for="(item, index) in msgList" :key="index">
              <div class="quotation-list-tit">
                <div>{{item.created_at}}</div>
                <div style="text-align: center;">{{item.content}}</div>
                <div style="text-align: right;">{{item.reply}}</div>
              </div>
            </div>
            <pagination @current-change="handleCurrentChange"  :total="total"></pagination>
            <no-data v-if="msgList.length<=0"></no-data>
            <div class="scroll-but"></div>

          </div>

        </div>
      </div>
    </div>
    <el-dialog
      :title="$t('my.lyfk')"
      :visible.sync="dialogVisible"
      width="40%"
      :modal-append-to-body="true"
      :append-to-body="true"
      :close-on-click-modal="false"
      :destroy-on-close="false"
      class="dialog-box sys-radius-20"
    >
      <div class="dialog-data" style="width: 100%;">
<!--        <div style="font-size:14px;margin-bottom:16px;color: rgba(255, 255, 255, 0.8);padding-top: 24px;">-->
<!--          {{ $t('my.lyfk') }}-->
<!--        </div>-->
        <div class="select-box">
          <input type="text" class="select-box-ipt" :placeholder=" $t('my.lyfk')" v-model="msg">
        </div>
        <div class="submit" @click="setMsg">{{ $t('withdraw.tj') }}</div>
      </div>
    </el-dialog>
    <!-- <footerb></footerb> -->
  </div>
</template>

<script>
import heads from '../../components/header.vue'
import footerb from '../../components/footer.vue'
import {getLoginList, getMemberInfo} from "@/api/member";
import {addMsg, getMsgList} from "@/api/news";
import Pagination from "@/components/pagination/pagination";
import NoData from "@/components/noData/noData.vue";
import tools from "@/utils/tools";
export default {
  data() {
    return {
      getData: [],
      activeIndex: 1,
      listObjeck:{},
      msgList:[],
      page:1,
      total:0,
      dialogVisible:false,
      msg:'',
    }
  },
  components: {
    NoData,
    Pagination,
    footerb,
    heads
  },
  created() {
    this.activeIndex = this.$route.query.activeIndex || 1
    this.getInfo()
    this.getLoginList()
    this.getMsgList()
  },
  methods: {
    setMsg(){
      addMsg({'content':this.msg}).then(res=>{
        if(res.code===1){
          this.page=1
          this.getMsgList()
          this.dialogVisible=false
          this.msg=''
        }else {
          tools.error(res.msg)
        }
      })
    },
    handleClose() {
      // 点击蒙层和×关闭弹框
     this.dialogVisible=false
    },
    handleCurrentChange(val) {
      console.log('val:' + val)
      this.page = val
      this.getMsgList()
    },
    getMsgList(){
      getMsgList({'page':this.page}).then(res => {
        if(res.code===1){
            this.msgList=res.data.items
            this.total=res.data.total
        }
      })
    },
    getLoginList(){
      getLoginList().then(res=>{
        if(res.code===1){
          this.getData=res.data.items
        }
      })
    },
    btn(id) {
      this.activeIndex = id
    },
    jumpRz() {
      this.$router.push({
        path: '/realname',
        query: {
          type: 2
        }
      })
    },
    addMsg(){
      this.dialogVisible=true
    },
    edit(id) {
      this.$router.push({
        path: '/editpwd',
        query: {
          type: id
        }
      })
    },
    getInfo(){
      getMemberInfo().then(res => {
        if (res.code === 1) {
          this.listObjeck = res.data
        }
      })

    },
  }
};

</script>
<style scoped>
.dialog-box{
  border-radius: 20px;
}
::v-deep .el-dialog__header {
  background: #171e28;
}

::v-deep .el-dialog__body {
  background: #171e28;
}
.dialog-data{
  background: #171e28;
}
.select-box-ipt {

  width: 80%;
  color: #FFF;

}
.submit {
  margin-bottom: 40px;
  margin-top: 40px;
  width: 100%;
  height: 48px;
  border-radius: 4px;
  background: #FFC300;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #212121;
  margin-bottom: 10px;
  cursor: pointer;
}
.select-box {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  background: #29343D;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  margin-bottom: 16px;
  box-sizing: border-box;
}

html {
  background: #171e28;
}
.fs-16{
  font-size: 16px;
}
.fs-12{
  font-size: 12px;
}
.main {
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
}

.main-left {
  width: 16%;
  height: 480px;
  background: #1F282F;
  cursor: pointer;
}

.main-right {
  width: 83%;
  height: 480px;
  background: #1F282F;
  overflow: hidden;
}

.main-left-tit {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
}

.active {
  color: #FFC300;
  background: #29343D;
}

.avter {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}

.edit {
  width: 16px;
  height: 16px;
}

.main-right-heade {
  display: flex;
  align-items: center;
  padding: 30px 40px;
  box-sizing: border-box;
}

.username {
  color: #FFFFFF;
  font-size: 24px;
  margin-right: 15px;
  margin-bottom: 10px
}

.account {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
}

.line {
  width: 100%;
  height: 10px;
  background: #171E28;
}

.quotation-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  border-bottom: 1px solid #171E28;
  padding-bottom: 10px;
  margin-bottom: 16px;
}

.quotation-list-tit {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 40px;
}

.quotation-list-tit div {
  width: 33.33%;
  font-size: 12px;
}

.record {
  padding: 15px 40px;
  color: #FFF;
  font-size: 16px;
  border-bottom: 1px solid #171E28;
  margin-bottom: 10px;

}

.scroll {
  max-height: 380px;
  overflow-y: scroll;
  padding-bottom: 60px;

}
.scroll-but{
  height: 20px;
}

::-webkit-scrollbar {
  width: 0;
}

.only-img{
  width: 68px;height: 68px !important;margin-right:20px;
}

.avter2 {
  width: 72px;
  height: 56px;
  margin-right: 20px;
}

.authentication {
  width: auto;
  height: 32px;
  opacity: 1;
  border-radius: 4px;
  background: #FFC300;
  font-size: 12px;
  color: #212121;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 10px;
}
.authentication-two{
  background: rgba(255, 255, 255,0.5);
}

.box-sm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 40px;
}

.line2 {
  width: 100%;
  height: 1px;
  background: #171E28;
}

.box-sm-img {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}
</style>
