import request from '@/utils/request'

const url = 'api/news/'

export const banner = (data) => request(url+'banner','get',data,true)
export const notice = (data) => request(url+'notices','get',data,true)
export const noticeDetails = (data) => request(url+'notice','get',data,true)
export const getAboutUs = (data) => request(url+'about_us','get',data,true)
export const addMsg = (data) => request(url+'add-msg','get',data,true)
export const getMsgList = (data) => request(url+'list-msg','get',data,true)

